<template>
    <div v-if="page">
        <h2>{{ $t('wallet-title') }}</h2>
        <div v-if="!!page.doc.content" class="row mb-3">
            <page-title :subheading="page.doc.content"></page-title>
        </div>

        <token-list page="wallet" :token-list="page.viewer.totals" :daily-loyalty="page.viewer.dailyLoyalty" />
        <deposit-addresses @update="update" :deposit-address="page.viewer.depositAddress" />
        <div class="row mb-3">
            <div class="col-md-6 col-12">
                <div class="container" style="margin: 0px; padding: 0px;">
                    <div class="row"
                        v-if="!btcWithdrawal(page.viewer) && (!page.viewer.totals.hasPendingWithdrawal && !page.viewer.totals.hasPendingUsdtWithdrawal)">
                        <withdraw-card class="col-12" @update="update" :limit="withdrawalLimit(page.viewer)"
                            :kyc="page.viewer.hasKYC" />
                    </div>
                    <div class="row"
                        v-if="btcWithdrawal(page.viewer) && (!page.viewer.totals.hasPendingWithdrawal && !page.viewer.totals.hasPendingUsdtWithdrawal)">
                        <withdraw-btc-card class="col-12" @update="update" :limit="withdrawalLimit(page.viewer)"
                            :kyc="page.viewer.hasKYC" />
                    </div>
                    <div class="row" v-if="page.viewer.totals.hasPendingWithdrawal">
                        <cancel-withdrawal class="col-12" @update="update" />
                    </div>
                    <div class="row"
                        v-if="!btcWithdrawal(page.viewer) && (!page.viewer.totals.hasPendingWithdrawal && !page.viewer.totals.hasPendingUsdtWithdrawal)">
                        <withdraw-usdt-card class="col-12" @update="update" :limit="withdrawalLimitUSDT(page.viewer)"
                            :maxAmount="page.viewer.totals.usdt" :kyc="page.viewer.hasKYC" />
                    </div>
                    <div class="row"
                        v-if="btcWithdrawal(page.viewer) && (!page.viewer.totals.hasPendingWithdrawal && !page.viewer.totals.hasPendingUsdtWithdrawal)">
                        <withdraw-usdt-to-btc-card class="col-12" @update="update" :limit="withdrawalLimitUSDT(page.viewer)"
                            :maxAmount="page.viewer.totals.usdt" :kyc="page.viewer.hasKYC" />
                    </div>
                    <div class="row"
                        v-if="btcWithdrawal(page.viewer) && (!page.viewer.totals.hasPendingWithdrawal && !page.viewer.totals.hasPendingUsdtWithdrawal)">
                        <withdraw-usdt-to-btc-card class="col-12" @update="update" :limit="withdrawalLimitUSDT(page.viewer)"
                            :maxAmount="page.viewer.totals.usdt" :kyc="page.viewer.hasKYC" />
                    </div>


                    <div class="row" v-if="page.viewer.totals.hasPendingUsdtWithdrawal">
                        <cancel-usdt-withdrawal class="col-12" @update="update" />
                    </div>
                </div>

            </div>
            <div class="col-md-6 col-12">
                <div class="container" style="margin: 0px; padding: 0px;">
                    <div class="row">
                        <exchange-card v-if="page.viewer.exchang3 === true" class="col-12" @update="update"
                            :maxAmount="page.viewer.totals.usdt" />
                    </div>
                </div>


            </div>
        </div>
        <transaction-table :transaction-list="page.viewer.transactions" />
        <v-card>
            <v-card-title>
                <div class="card-header text-unset ">
                    <h5><b>Vouchers</b></h5>
                </div>
                <v-spacer></v-spacer>
            </v-card-title>
            <v-data-table class="mb-3" v-if="vouchers" dense :headers="headers" :items="vouchers" item-key="id"
                :items-per-page="10">
                <template v-slot:items="props">
                    <td class="text-right w-5">
                        <font-awesome-icon style="font-size: 1.4em" :icon="['fas', 'tag']" class="text-primary"
                            v-bind:class="{ 'text-danger': props.item.used != 'Not used yet' }"></font-awesome-icon>
                    </td>
                    <td>
                        {{ props.item.name }}
                    </td>
                    <td class="date-time-width">
                        {{ props.item.gotDate | formatDateShort }}
                    </td>
                    <td class="date-time-width">
                        {{ props.item.usedDate | formatDateShort }}
                    </td>
                    <td style="width: 100px">
                        {{ props.item.value }}
                    </td>

                    <td>
                        {{ props.item.used }}
                    </td>
                </template>
            </v-data-table>
        </v-card>

        <v-card>
            <v-card-title>
                <div class="card-header text-unset">
                    <h5><b>Reservations</b></h5>
                </div>
                <v-spacer></v-spacer>
            </v-card-title>
            <v-data-table v-if="reservations" dense :headers="headersRes" :items="reservations" item-key="id"
                :items-per-page="10">
                <template v-slot:items="props">
                    <td class="text-right  w-5">
                        <font-awesome-icon style="font-size: 1.4em" :icon="['fas', 'calendar-alt']"
                            class="text-primary"></font-awesome-icon>
                    </td>
                    <td>
                        {{ props.item.vrednostRezervacije }}
                    </td>
                    <td>
                        {{ property(props.item.nepremicnina) }}
                    </td>
                    <td class="date-time-width">
                        {{ props.item.zacetekRezervacije | formatDateShort }} - {{ props.item.konecRezervacije |
                            formatDateShort
                        }}
                    </td>

                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>



import TokenList from "../components/Dashboard/TokenList";
import DepositAddresses from "../components/Wallet/DepositAddresses";
import WithdrawCard from "../components/Wallet/WithdrawCard";
import ExchangeCard from "../components/Wallet/ExchangeCard";
import WithdrawBtcCard from "../components/Wallet/WithdrawBtcCard";
import WithdrawUsdtToBtcCard from "../components/Wallet/WithdrawUsdtToBtcCard";
import WithdrawUsdtCard from "../components/Wallet/WithdrawUsdtCard";

import CancelWithdrawal from "@/components/Wallet/CancelWithdrawal";
import CancelUsdtWithdrawal from "@/components/Wallet/CancelUsdtWithdrawal";
import gql from "graphql-tag";
import PageTitle from "@/components/common/PageTitle";
import TransactionTable from "@/components/Wallet/TransactionTable";
import * as moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTag, faCalendarAlt } from '@fortawesome/free-solid-svg-icons'

library.add(faTag, faCalendarAlt);
export default {
    name: "MyWallet",
    components: {
        TransactionTable,
        PageTitle,
        TokenList,
        DepositAddresses,
        WithdrawCard,
        WithdrawUsdtCard,
        WithdrawBtcCard,
        WithdrawUsdtToBtcCard,
        CancelWithdrawal,
        CancelUsdtWithdrawal,
        ExchangeCard,
        FontAwesomeIcon
    },
    data() {
        return {
            page: null,
            reservations: null,
            vouchers: null,
            headers: [
                { value: 'icon', text: "", sortable: false },
                { value: "name", text: "Voucher", sortable: true },
                {
                    value: "gotDate",
                    text: "Date issued",
                    sortable: true,
                },
                {
                    value: "usedDate",
                    text: "Date used",
                    sortable: true,
                },
                { value: "value", text: "Value", sortable: true },
                { value: "used", text: "Used", sortable: true },
            ],
            headersRes: [
                { value: 'icon', text: "", sortable: false },
                { value: "value", text: "Reservation value", sortable: true },
                { value: "name", text: "Property name", sortable: true },
                { value: "date", text: "Date", sortable: true },
            ]
        }
    },
    methods: {
        update() {
            this.$apollo.queries.page.refetch()
        },
        btcWithdrawal(user) {
            const restricted = ['AF', 'AU', 'CN', 'ET', 'HK', 'IQ', 'IR', 'KP', 'LK', 'PK', 'SY', 'TN', 'UG', 'US', 'VN', 'YE']
            return restricted.includes(user.country) && !user.isWithdrawalDisabled && !user.totals.hasPendingWithdrawal
        },
        htlWithdrawal(user) {
            return !user.isWithdrawalDisabled && !user.totals.hasPendingWithdrawal
        },
        withdrawalLimit(user) {
            return Math.min(user.withdrawalLimit, user.totals.tokens / 1e8)
        },
        withdrawalLimitUSDT(user) {
            return Math.min(user.withdrawalLimit, user.totals.usdt / 1e8)
        },
        property(val) {
            return (val == 1) ? "Harmony" : "Simphony"
        },
        cancelRes(val) {
            console.log(val)
        }
    },
    computed: {
        activeVouchers() {
            return this.vouchers.filter(v => v.usedDate == null);
        }
    },
    apollo: {
        page: {
            query: gql`query page {
                    doc(id:"wallet"){id,content,title},
                    viewer{
                        id,hasKYC,country,depositAddress{id,address,currency,expiresAt},
                        totals{tokens,usdt,withdrawn,inactive,shares,sharesA,promotionalSharesA,loyalty,held,packages,rank,totalBinaryCommission,totalDirectCommission,totalMatchingCommission,hasPendingWithdrawal,hasPendingUsdtWithdrawal,cummulatedDividends, freedom},
                        transactions{identificator, createdAt,type,rank,oldRank,tokens,usdt,inactive,held,shares,sharesA,promotionalSharesA,loyalty,txCurrency,txValue,txTo,poolId,poolMultiplier,poolStatus,oldPoolStatus,comment,tickets{code,quantity}},
                        withdrawalLimit(currency:"HTL"), exchang3},
                    isWithdrawalDisabled,
                    viewerIsAdmin
                }`,
            update: data => data,
            result({ data }) {
                if (data) {
                    this.$store.commit('user/updateAvailable', {
                        tokens: data.viewer.totals.tokens
                    })
                    if (data.viewerIsAdmin) {
                        this.$store.commit('user/setAdmin')
                    }
                }
            }
        }
    },
    created() {
        this.axios.post(this.baseURL + '/admin-api/v0/bc/rezervacije?access_token=' + ((sessionStorage.getItem('apollo-token')) ? sessionStorage.getItem('apollo-token') : localStorage.getItem('apollo-token'))).then(res => {
            this.reservations = res.data
            this.reservations.forEach(el => {
                el.zacetekRezervacije = moment(new Date(el.zacetekRezervacije)).format('YYYY-MM-DD')
                el.konecRezervacije = moment(new Date(el.konecRezervacije)).format('YYYY-MM-DD')
            })
        })

        this.axios.post(this.baseURL + '/admin-api/v0/bc/vavcerji?access_token=' + ((sessionStorage.getItem('apollo-token')) ? sessionStorage.getItem('apollo-token') : localStorage.getItem('apollo-token'))).then((response) => {
            this.vouchers = response.data.map(v => {
                return {
                    gotDate: v.datumIzdaje,
                    usedDate: v.datumPorabe,
                    name: v.ime,
                    used: (v.porabljen) ? moment(v.datumPorabe).format('D. MMM YYYY') : 'Not used yet',
                    value: v.vrednost,
                }

            })

        })
    }
}
</script>

<style scoped></style>