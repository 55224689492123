<template>
  <v-card class="mb-3">
    <v-card-title>
      <div class="card-header text-unset">History</div>
      <v-spacer></v-spacer>
      <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
    </v-card-title>
    <v-data-table dense :headers="headers" :items="transactionsL" :search="search" item-key="id">
      <template v-slot:items="props">
        <td class="icon-column px-3">
          <div class="dot" :class="'bg-' + props.item.color + ' border-' + props.item.color">
            <font-awesome-icon v-if="props.item.icon" :icon="props.item.icon" :class="'icon-' + props.item.color"
              class="icon-white" size="lg" />
          </div>
        </td>
        <td class="timestamp-column px-1">
          <div>{{ props.item.createdAt | formatDate }}</div>
        </td>
        <td class="px-1">
          <div>{{ props.item.text }}</div>
          <div v-if="props.item.text2">(<span style="font-style: italic;">{{ props.item.text2 }}</span>)</div>
        </td>
        <td class="px-1">
          <p v-if="props.item.tokens">
            <b v-if="props.item.type === 'dividends'">{{
              $t("tokens.dividends")
            }}</b><b v-else>{{ $t("tokens.available") }}</b>&nbsp;
            <span>{{ props.item.tokens | formatHtl }}</span>
          </p>
          <p v-if="props.item.inactive">
            <b>{{ $t("tokens.inactive") }}</b>&nbsp;
            <span>{{ props.item.inactive | formatHtl }}</span>
          </p>
          <p v-if="props.item.sharesA">
            <b v-if="props.item.type === 'dividends'">{{
              $t("tokens.dividendsA")
            }}</b><b v-else>{{ $t("tokens.sharesA") }}</b>&nbsp;
            <span>{{ props.item.sharesA | formatHtl }}</span>
          </p>
          <p v-if="props.item.shares">
            <b v-if="props.item.type === 'dividends'">{{
              $t("tokens.dividendsB")
            }}</b><b v-else>{{ $t("tokens.sharesB") }}</b>&nbsp;
            <span>{{ props.item.shares | formatHtl }}</span>
          </p>
          <p v-if="props.item.held">
            <b>{{ $t("tokens.sharesB") }}</b>&nbsp;
            <span>{{ props.item.held | formatHtl }}</span>
          </p>
          <p v-if="props.item.loyalty">
            <b>{{ $t("tokens.loyalty") }}</b>&nbsp;
            <span>{{ props.item.loyalty | formatHtl }}</span>
          </p>
          <p v-if="props.item.usdt">
            <b>USDT</b>&nbsp;
            <span>{{ props.item.usdt | formatHtl }}</span>
          </p>
          <p v-if="props.item.tickets">
            <b>Tickets</b>
            <span v-for="ticket in props.item.tickets" :key="ticket.code"><br>{{ ticket | formatTicket }}</span>
          </p>
          <p v-if="props.item.promotionalSharesA">
            <b>Promotional shares A</b>&nbsp;
            <span>{{ props.item.promotionalSharesA | formatHtl }}</span>
          </p>
        </td>
      </template>
      <v-alert v-slot:no-results :value="true" color="error" icon="warning">
        {{ $t("search-no-result", [search]) }}
      </v-alert>
    </v-data-table>
  </v-card>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faStar,
  faCoins,
  faChartLine,
  faExchangeAlt,
  faMoneyBillAlt,
  faHandHoldingHeart,
  faBox,
  faBoxOpen,
  faBoxes,
  faParachuteBox,
  faTicketAlt,
  faRandom,
  faDownload,
  faAngleDoubleLeft,
  faClipboardCheck,
  faSignInAlt,
  faRecycle,
  faCommentsDollar,
  faLeaf,
  faUmbrellaBeach,
  faCalendarMinus
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faStar,
  faCoins,
  faChartLine,
  faExchangeAlt,
  faMoneyBillAlt,
  faHandHoldingHeart,
  faBox,
  faBoxOpen,
  faBoxes,
  faParachuteBox,
  faTicketAlt,
  faRandom,
  faDownload,
  faAngleDoubleLeft,
  faClipboardCheck,
  faSignInAlt,
  faRecycle,
  faCommentsDollar,
  faLeaf,
  faUmbrellaBeach,
  faCalendarMinus
);

export default {
  name: "TransactionTable",
  components: {
    "font-awesome-icon": FontAwesomeIcon,
  },
  props: ["transactionList"],
  data() {
    return {
      search: "",
      headers: [
        {
          value: "icon",
          text: "",
          sortable: false,
        },
        {
          value: "createdAd",
          text: this.$i18n.t("transaction-date"),
          sortable: true,
        },
        {
          value: "type",
          text: this.$i18n.t("transaction-type"),
          sortable: true,
        },
        { value: "amounts", text: this.$i18n.t("transaction-amounts") },
      ],
      transactionsL: null,
    };
  },
  beforeMount() {
    this.transactionsL = this.transactionList.map((tx) =>
      this.transactionData(tx)
    );
  },
  methods: {
    transactionData(tr) {
      const pools = [
        this.$i18n.t("pool-none"),
        this.$i18n.t("pool"),
        this.$i18n.t("pool-plus"),
        this.$i18n.t("pool-founder"),
      ];
      const ranks = [
        this.$i18n.t("rank-0"),
        this.$i18n.t("rank-1"),
        this.$i18n.t("rank-2"),
        this.$i18n.t("rank-3"),
        this.$i18n.t("rank-4"),
        this.$i18n.t("rank-5"),
        this.$i18n.t("rank-6"),
        this.$i18n.t("rank-7"),
        this.$i18n.t("rank-8"),
        this.$i18n.t("rank-9"),
        this.$i18n.t("rank-10"),
        this.$i18n.t("rank-11"),
      ];

      switch (tr.type) {
        case "binaryCommission":
          return {
            color: "success",
            icon: "money-bill-alt",
            text: this.$t("transaction-types.binary-commission"),
            ...tr,
          };
        case "bonus":
          return {
            color: "primary",
            icon: "star",
            text: this.$t("transaction-types.bonus"),
            ...tr,
          };
        case "convertToHeld":
          return {
            color: "primary",
            icon: "exchange-alt",
            text: this.$t("transaction-types.move-to-sharesB"),
            ...tr,
          };
        case "convertToShareA":
          return {
            color: "primary",
            icon: "exchange-alt",
            text: this.$t("transaction-types.move-to-sharesA"),
            ...tr,
          };
        case "convertToShare":
          return {
            color: "primary",
            icon: "exchange-alt",
            text: this.$t("transaction-types.move-to-sharesB"),
            ...tr,
          };
        case "deposit":
          return {
            color: "success",
            icon: "coins",
            text: (tr.txCurrency === 'EUR')
              ? this.$t('Deposit')
              : this.$t("transaction-types.deposit", [
                tr.txValue / 1e8,
                tr.txCurrency,
              ]),
            ...tr,
          };
        case "directCommission":
          return {
            color: "success",
            icon: "money-bill-alt",
            text: this.$t("transaction-types.direct-commission"),
            ...tr,
          };
        case "freedomPortfolioLevelUpdate":
          return {
            color: "success",
            icon: "leaf",
            text: "Reached a new Freedom Portfolio level " + tr.comment,
            ...tr
          };
        case "donateTokens":
          return {
            color: "primary",
            icon: "hand-holding-heart",
            text: this.$t("transaction-types.donation"),
            ...tr,
          };
        case "matchingCommission":
          return {
            color: "success",
            icon: "money-bill-alt",
            text: this.$t("transaction-types.matching-commission"),
            ...tr,
          };
        case "moveToAvailable":
          return {
            color: "primary",
            icon: "user-clock",
            text: this.$t("transaction-types.move"),
            ...tr,
          };
        case "startFreedomProgram":
          return {
            color: "success",
            icon: "sign-in-alt",
            text: "Started Freedom program",
            text2: "ID: " + tr.identificator,
            ...tr,
          };
        case "stopFreedomProgram":
          return {
            color: "danger",
            icon: "sign-out-alt",
            text: "Stoped Freedom program",
            text2: "ID: " + tr.identificator,
            ...tr,
          };
        case "packageFromFP":
          return {
            color: "success",
            icon: "box",
            text: "New Freedom Program package",
            text2: "ID: " + tr.identificator,
            ...tr,
          };
        case "freedomProgramDeposit":
          return {
            color: "success",
            icon: "clipboard-check",
            text: "Freedom Program deposit",
            text2: "ID: " + tr.identificator,
            ...tr,
          };

        case "freedomProgramDepositBonus":
          return {
            color: "success",
            icon: "clipboard-check",
            text: "Bonus Freedom Program deposit",
            text2: "ID: " + tr.identificator,
            ...tr,
          };

        case "packageBought":
          return {
            color: "success",
            icon: "box",
            text: this.$t("transaction-types.package-bought"),
            text2: "ID: " + tr.identificator,
            ...tr,
          };

        case "packageUpgraded":
          return {
            color: "success",
            icon: "boxes",
            text: this.$t("transaction-types.package-upgraded"),
            text2: "ID: " + tr.identificator,
            ...tr,
          };
        case "packageReleased":
          return {
            color: "danger",
            icon: "box-open",
            text: this.$t("transaction-types.package-released"),
            text2: "ID: " + tr.identificator,
            ...tr,
          };
        case "packageReward":
          return {
            color: "success",
            icon: "parachute-box",
            text: this.$t("transaction-types.package-loyalty"),
            text2: "ID: " + tr.identificator,
            ...tr,
          };
        case "freedomProgramReward":
          return {
            color: "success",
            icon: "parachute-box",
            text: "Freedom Program Loyalty",
            text2: "ID: " + tr.identificator,
            ...tr
          };
        case "returnedSharesA":
          return {
            color: "warning",
            icon: "angle-double-left",
            text: this.$t("36 months of promotional loyalty HTL Shares A are expired"),
            // text2: "ID: " + tr.id,
            ...tr,
          };
        case "oneTimeMoveAvailableToUSDT":
          return {
            color: "warning",
            icon: "recycle",
            text: this.$t("Conversion by terms and conditions"),
            ...tr
          };
        case "exchangeUSDT2HTL":
          return {
            color: "success",
            icon: "exchange-alt",
            text: this.$t("Exchanged USDT to HTL"),
            ...tr
          };
        case "poolPayout":
          return {
            color: "success",
            icon: "coins",
            text:
              [
                "",
                this.$t("transaction-types.pool"),
                this.$t("transaction-types.pool-plus"),
                this.$t("transaction-types.founder"),
              ][tr.poolId] +
              (tr.poolMultiplier > 1 ? " (x" + tr.poolMultiplier + ")" : ""),
            ...tr,
          };
        case "poolUpdate":
          return {
            color: tr.oldPoolStatus > tr.poolStatus ? "success" : "danger",
            icon: "chart-line",
            text:
              this.$t("transaction-types.pool-update") +
              " (" +
              pools[tr.oldPoolStatus] +
              " 🡒 " +
              pools[tr.poolStatus] +
              ")",
            ...tr,
          };
        case "rankUpdate":
          return {
            color: tr.oldRank > tr.rank ? "success" : "danger",
            icon: "chart-line",
            text:
              this.$t("transaction-types.rank-update") +
              " (" +
              ranks[tr.oldRank] +
              " 🡒 " +
              ranks[tr.rank] +
              ")",
            ...tr,
          };
        case "teamCommission":
          return {
            color: "success",
            icon: "money-bill-alt",
            text: this.$t("transaction-types.team-commission"),
            ...tr,
          };
        case "ticketBought":
          return {
            color: "primary",
            icon: "ticket-alt",
            text: this.$t("transaction-types.ticket-bought", [tr.comment]),
            ...tr,
          };
        case "ticketSend":
          return {
            color: "warning",
            icon: "ticket-alt",
            text: this.$t(tr.comment),
            ...tr
          };
        case "ticketReceived":
          return {
            color: "primary",
            icon: "ticket-alt",
            text: this.$t(tr.comment),
            ...tr
          };
        case "conversion":
          return {
            color: "warning",
            icon: "recycle",
            text: this.$t(tr.comment),
            createdAt: tr.createdAt,
            usdt: tr.usdt
          };
        case "dividends":
          return {
            color: "primary",
            icon: "ticket-alt",
            text: this.$t("transaction-types.dividends", [
              new Date(tr.createdAt).getUTCFullYear() - 1,
              parseFloat(tr.comment).toFixed(5),
            ]),
            ...tr,
          };
        case "tokenActivation":
          return {
            color: "success",
            icon: "coins",
            text: this.$t("transaction-types.token-activation"),
            ...tr,
          };
        case "transfer":
          return {
            color: "primary",
            icon: "random",
            text: this.$t("transaction-types.transfer"),
            ...tr,
          };
        case "withdrawal":
          return {
            color: "primary",
            icon: "download",
            text: this.$t("transaction-types.withdrawal"),
            ...tr,
          };
        case "withdrawalRequest":
          return {
            color: "success",
            icon: "download",
            text: this.$t("transaction-types.withdrawal-requested"),
            usdt: tr.usdt,
            ...tr,
          };
        case "withdrawalCanceled":
          return {
            color: "danger",
            icon: "download",
            text: this.$t("transaction-types.withdrawal-canceled"),
            usdt: tr.usdt,
            ...tr,
          };
        case "withdrawalConfirmed":
          return {
            color: "primary",
            icon: "download",
            text: this.$t("transaction-types.withdrawal-confirmed"),
            ...tr,
          };
        case "sellBackRequest":
          return {
            color: "primary",
            icon: "comments-dollar",
            text: "Sell Back request",
            ...tr
          };
        case "sellbackConfirmed":
          return {
            color: "success",
            icon: "comments-dollar",
            text: "Sell Back confirmed",
            // text2: "ID: " + tr.id,
            ...tr
          };
        case "sellbackCanceled":
          return {
            color: "warning",
            icon: "comments-dollar",
            text: "Sell Back canceled",
            // text2: "ID: " + tr.id,
            ...tr
          };
        case "packageSold":
          return {
            color: "primary",
            icon: "exchange-alt",
            text: this.$t("transaction-types.package-sold", [tr.packageType]),
            // text2: "ID: " + tr.id,
            ...tr,
          };
        case "packageBoughtBack":
          return {
            color: "primary",
            icon: "exchange-alt",
            text: this.$t("transaction-types.package-bought-back", [
              tr.packageType,
            ]),
            ...tr,
          };
        case "secureSell":
          return {
            color: "danger",
            icon: "calendar-minus",
            text: "Secure sell",
            text2: tr.comment,
            ...tr
          };
        case "secureSellRequest":
          return {
            color: "primary",
            icon: "calendar-minus",
            text: "Secure sell request",
            text2: tr.comment,
            ...tr
          };
        case "secureSellCanceled":
          return {
            color: "warning",
            icon: "calendar-minus",
            text: "Secure sell canceled",
            text2: tr.comment,
            ...tr
          };
        case "packageToSharesB":
          return {
            color: "primary",
            icon: "exchange-alt",
            text: "Package transform to Shares B",
            text2: "ID: " + tr.identificator,

            ...tr
          };
        case "availableToSharesB":
          return {
            color: "success",
            icon: "exchange-alt",
            text: "Converted Available into Shares B",
            ...tr
          };
        case "buyback":
          return {
            color: "success",
            icon: "exchange-alt",
            text: "Buy Back from user",
            text2: tr.comment,
            ...tr
          };
        case "ERABought":
          return {
            color: "success",
            icon: "umbrella-beach",
            text: "Purchased Excusive Resorts Access",
            ...tr
          };
        default:
          return {
            color: "primary",
            icon: null,
            text: tr.createdAt,
            ...tr,
          };
      }
    },
  },
};
</script>

<style scoped>
.icon-primary {
  color: #56aaff;
}

.icon-success {
  color: #8bca63;
}

.icon-danger {
  color: #c71c22;
}

.icon-white {
  color: white;
}

td>p {
  margin: 2px 0px;
}

.dot {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.icon-column {
  max-width: 30px;
}

.timestamp-column {
  min-width: 60px;
}
</style>